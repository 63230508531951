<template>
  <Teaser class="scratchcard-teaser">
    <template #body>
      <section class="w-full flex flex-col items-center gap-md mt-md">
        <NuxtLink class="link w-full flex justify-center" v-bind="{ to }">
          <ScratchcardDisplay
            v-bind="{ fetchpriority, name, price }"
            :height="190"
            :is-caption-visible="!teaserSrc"
            :is-price-visible="!teaserSrc && !!price"
            :jackpot="amount"
            :primary-color="visualParameters?.primaryColor"
            :secondary-color="visualParameters?.secondaryColor"
            :src="teaserSrc"
            :width="190"
          />
        </NuxtLink>

        <div class="w-full padding">
          <LcButton class="w-full" no-padding v-bind="{ to }">
            {{ $t('scratchcard.teaser.button.scratchnow') }}
          </LcButton>
        </div>
      </section>
    </template>
  </Teaser>
</template>

<script lang="ts" setup>
import { LcButton, type LcImageProps } from '@lottocom/frontend-components'

import type {
  Money,
  ScratchcardVisualParameters,
} from '~/@types/generated/backend/graphql-schema-types'

export type ScratchcardTeaserProps = Pick<LcImageProps, 'fetchpriority'> & {
  amount?: Maybe<Money>
  name: string
  price?: Maybe<Money>
  slug: string
  visualParameters?: Maybe<Omit<ScratchcardVisualParameters, 'scratchArea'>>
}

const props = defineProps<ScratchcardTeaserProps>()

const { toUrl } = useClientUrl()

// TODO SF-4090 remove after rollout
const teaserSrc = computed(() => {
  if (props.visualParameters?.teaserImage) {
    return toUrl(props.visualParameters?.teaserImage)
  }
  if (props.visualParameters?.teaserImageUrl) {
    return toUrl(props.visualParameters?.teaserImageUrl)
  }
  return null
})
const to = computed(() => ({ path: `/scratchcard/${props.slug}` }))
</script>

<style lang="scss" scoped>
.scratchcard-teaser {
  .link {
    @include focus-state {
      &::before {
        inset: 0;
        border-radius: $border-radius-sm;
        box-shadow:
          0 0 0 0.125rem color('white'),
          0 0 0 0.3125rem color('info700');
      }
    }

    position: relative;
    width: 190px;
  }
}

.padding {
  padding: 0 4.5rem;

  @include media-mobile-small {
    padding: 0 2rem;
  }
}
</style>
